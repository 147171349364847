.App {
  text-align: center;
}

body {
  background: rgb(248, 248, 248);
}

h3 {
  color: rgb(40, 72, 141);
}


.search-results-container {
  width: 75%;
  float: right;
  margin-top: 15.4vh;
  height: 79vh;
}

.search-results-container>.add-scroll {
  overflow-y: scroll;
}

.label-value-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 3em;
}

.push-down-2 {
  margin-bottom: 2em;
}

.space-between {
  justify-content: space-between;
}

.label-value-info>.width-29 {
  width: 29em;
}

.width-15 {
  width: 15em;
}

.width-30 {
  width: 30em;
}


.search-container {
  width: 25%;
  float: left;
  background: white;
  margin-top: 10vh;
  min-height: 90vh;
}

.search-container-no-months {
  height: 90vh;
}

.details-page {
  padding: 3em 4em;
  height: 77vh;
  margin-top: 7.3em;
}

.details-page>.page {
  background: white;
  padding: 3em 4em;
}

.details-page>.page>.navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.details-page>.page a {
  color: black;
  text-decoration: none;
  font-size: 20px;
}

.details-page>.page header {
  color: rgb(40, 72, 141);
  text-decoration: none;
  font-size: 24px;
}

.details-page>.page>.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.details-page>.page>.flex h1 {
  flex-grow: 1;
}

.details-page>.page>.flex>.justify-end {
  display: flex;
}

.details-container h1 {
  font-size: 18px;
  color: rgb(61, 60, 60);
}

.details-container h2 {
  font-size: 15px;
  color: black;
}

.h2height {
  height: 47px;
}

.details {
  background: rgb(242, 246, 234);
  /*  padding: 3em 3em;*/
}

.text-center {
  text-align: center;
}

.blue-box {
  padding: 0.5em 1em;
  height: 1.2em;
  border-radius: 5px;
  font-size: 16px;
  margin-left: 10px;
  color: black;
  font-weight: 600;
}

.button-box {
  height: 1.2em;
  border-radius: 5px;
  margin-left: 10px;
}

.nav-wrapper {
  height: 100%;
}

.primary {
  color: #2738A5;
}

.secondary {
  color: #27a594;

}

.outward {
  margin-top: 2.5em;
  margin-bottom: 2.5em;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.start-search {
  text-align: center;
  padding: 1em;
  padding-top: 0em;
  color: rgb(40, 72, 141);
}

.start-search>h1 {
  font-size: 36px;
  font-weight: 500;
  margin-top: 1em;
}

.skeleton {
  padding-left: 6em;
  /* margin-top: 26px; */
}

.start-search>p {
  font-size: 28px;
  font-weight: 500;
  padding: 0em 5em;
}

.center-search-items {
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  padding: 11px 2.5em;
}

.center-no-months {
  padding: 0px 2.5em;
}

.loading {
  text-align: center;
  margin: 10em auto;
}

.loading-text {
  width: 58px;
  margin: 0 auto;
  color: #3f51b5;
  font-weight: bold;
}

.center-search-items>.MuiFormControl-root:not(.optional) {
  margin-top: 1em;
  margin-bottom: 1em;
}

.optional {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.push-down {
  margin-bottom: 1em;
}

.squish {
  padding: 0em 3em;
  padding-left: 0em;
  padding-right: 1em;
}

.additional-section {

  padding: 24px !important;
}

.card-label-column {
  width: 30%;
}

.wrap-new-lines {
  white-space: pre-line;
}

.below-header {
  margin-top: 15.4vh;
}

